import { useRef, useState } from 'react'
import ReactYouTube from 'react-youtube'

import BEMHelper from '../../helpers/bem'
import Icon from '../Icon'
import Modal from '../Modal'
import styles from './Styles.module.scss'

const bem = BEMHelper(styles)

type YouTubeProps = {
  type?: string
  id: string
  title?: string
  caption?: string
  dimensions?: object
  className?: string
  square?: boolean
}
export default function YouTube({ type, id, title, caption, className, square }: YouTubeProps) {
  const triggerRef = useRef<HTMLButtonElement>(null)
  const [expanded, setExpanded] = useState(false)
  const styles = { paddingBottom: '56.25%' }

  const toggle = (value) => () => {
    if (value) {
      setExpanded(true)
    } else {
      setExpanded(false)
    }
  }

  return (
    <>
      <figure {...bem('', type, className)}>
        <button
          type="button"
          {...bem('trigger', { square })}
          onClick={toggle(true)}
          ref={triggerRef}
          style={{
            backgroundImage: `url(https://img.youtube.com/vi/${id}/hqdefault.jpg)`,
          }}
        >
          <span {...bem('play')}>
            <Icon icon="play" />
          </span>

          {title && <h2 {...bem('title')}>{title}</h2>}
        </button>

        {caption && <figcaption {...bem('caption')}>{caption}</figcaption>}
      </figure>

      <Modal
        expanded={!!expanded}
        fromElement={triggerRef?.current}
        onClose={toggle(false)}
        preventClickOutside
        autoWidth={false}
      >
        <div {...bem('aspect')} style={styles}>
          <ReactYouTube
            {...bem('video')}
            videoId={id}
            opts={{
              playerVars: {
                rel: 0,
                // playlist: 0,
                modestbranding: 1,
                origin: 1,
                autoplay: 1,
              },
            }}
          />
        </div>
      </Modal>
    </>
  )
}
