import BEMHelper from '@/helpers/bem'
import videojs from '@mux/videojs-kit'
import '@mux/videojs-kit/dist/index.css'
import { useEffect, useRef, useState } from 'react'
import Icon from '../Icon'
import Modal from '../Modal'
import styles from './Styles.module.scss'
import './videojs-custom-theme.scss'

const bem = BEMHelper(styles)

type VideoPlayerProps = {
  playbackId: string
  caption?: string
  title?: string
  hideCaption?: boolean
  aspectRatio?: string
}

const VideoJS: React.FC<{ playbackId: string }> = ({ playbackId }) => {
  const videoRef = useRef(null)

  useEffect(() => {
    const player = videojs(videoRef.current, {
      fluid: true,
      fill: true,
      breakpoints: {
        sm: 0,
        md: 768,
        lg: 1024,
        xl: 1440,
      },
      controlBar: {
        pictureInPictureToggle: false,
      },
    })

    player.autoplay('true')

    player.on('play', () => {
      player.addClass('vjs-has-started')
    })

    return () => {
      if (player.isDisposed()) {
        player.dispose()
      }
    }
  }, [])

  return (
    <video
      ref={videoRef}
      id="mux-default"
      className="video-js vjs-fluid vjs-faktisk vjs-default-skin"
      controls
      preload="false"
      width="100%"
      data-setup="{}"
      poster={`https://image.mux.com/${playbackId}/thumbnail.jpg?width=1920`}
    >
      <source src={playbackId} type="video/mux" />
    </video>
  )
}

export const VideoPlayer = ({
  playbackId,
  title,
  caption,
  hideCaption = false,
  aspectRatio,
}: VideoPlayerProps) => {
  const triggerRef = useRef<HTMLButtonElement>(null)
  const [expanded, setExpanded] = useState(false)

  const toggle = (value) => () => {
    if (value) {
      setExpanded(true)
    } else {
      setExpanded(false)
    }
  }

  return (
    <>
      <figure {...bem('', { aspect: aspectRatio })}>
        <button
          type="button"
          {...bem('trigger')}
          onClick={toggle(true)}
          ref={triggerRef}
          style={{
            backgroundImage: `url(https://image.mux.com/${playbackId}/thumbnail.jpg?width=1920)`,
            '--aspect': aspectRatio,
          }}
        >
          <span {...bem('play')}>
            <Icon icon="play" />
          </span>
          <h2 {...bem('title')}>{title}</h2>
        </button>

        {!hideCaption && caption && <figcaption {...bem('caption')}>{caption}</figcaption>}
      </figure>

      <Modal
        expanded={!!expanded}
        fromElement={triggerRef?.current}
        onClose={toggle(false)}
        preventClickOutside
        autoWidth
      >
        <VideoJS playbackId={playbackId} />
      </Modal>
    </>
  )
}

export default VideoPlayer
