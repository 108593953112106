/* eslint-disable @typescript-eslint/no-explicit-any */
import NextImage from 'next/image'
import { useRouter } from 'next/router'
import { CSSProperties, useRef, useState } from 'react'

import Button from '@/components/Button'
import Icon from '@/components/Icon'
import Image from '@/components/Image'
import Link from '@/components/Link'
import Transition from '@/components/Transition'
import VideoPlayer from '@/components/VideoPlayer'
import YouTube from '@/components/YouTube'

import scrollTo from '@/helpers/scrollTo'

import BEMHelper from '@/helpers/bem'
import styles from './Styles.module.scss'
const bem = BEMHelper(styles)

const getScoreFeedback = ({ score, total, feedback }) => {
  const percentage = (score / total) * 100

  if (percentage <= 30) {
    return feedback.bad || null
  } else if (percentage > 30 && percentage <= 70) {
    return feedback.average || null
  } else if (percentage > 70 && percentage !== 100) {
    return feedback.good || null
  } else if (percentage === 100) {
    return feedback.perfect || null
  }

  return null
}

const STEP_DELAY = 250
const STEP_DELAY_INLINE = 3000 // Need to be rounded to 1000

// TODO: Should be typed
export default function Quiz({
  title,
  preamble,
  feedback = {},
  questions = [] as any[],
  inlineAnswers,
  fluid,
}) {
  const router = useRouter()
  const nextTimerRef = useRef<NodeJS.Timeout | undefined>(undefined)
  const intervalRef = useRef<NodeJS.Timeout>()
  const wrapperRef = useRef<HTMLElement | null>(null)
  const [count, setCount] = useState<number | undefined>(undefined)
  const [showAnswerBox, setShowAnswerBox] = useState(false)
  const [question, setQuestion] = useState(0)
  const [preventScroll, setPreventScroll] = useState(false)
  const [answers, setAnswers] = useState({})
  const backTo = router?.query?.referer || '/'

  const restart = () => {
    setQuestion(0)
    setAnswers({})
    setShowAnswerBox(false)
    setPreventScroll(true)
    scrollTo(0, 600, wrapperRef.current)

    setTimeout(() => {
      setPreventScroll(false)
    }, 1500)
  }

  const stages = [
    { _key: 'intro', title, preamble, type: 'intro' },
    ...questions,
    { type: 'score', _key: 'score' },
  ]

  const back = () => {
    clearTimeout(nextTimerRef.current)
    setPreventScroll(true)
    setShowAnswerBox(false)
    setQuestion((question) => Math.max(0, question - 1))
    scrollTo(0, 600, wrapperRef.current)

    setTimeout(() => {
      setPreventScroll(false)
    }, 1500)
  }

  const next = () => {
    clearTimeout(nextTimerRef.current)
    setPreventScroll(true)
    setShowAnswerBox(false)
    setQuestion((question) => Math.min(stages.length - 1, question + 1))
    scrollTo(0, 600, wrapperRef.current)

    setTimeout(() => {
      setPreventScroll(false)
    }, 1500)
  }

  const handleAnswerBox = () => {
    setShowAnswerBox(true)
    setCount(STEP_DELAY_INLINE / 1000)

    intervalRef.current = setInterval(() => {
      setCount((count) => (count !== undefined ? count - 1 : 0))
    }, 1000)

    setTimeout(() => {
      clearInterval(intervalRef.current)
    }, STEP_DELAY_INLINE)
  }

  const current = stages[question]

  const selectAnswer = (question, key, correct) => () => {
    setAnswers((answers) => ({
      ...answers,
      [question]: { key, correct },
    }))

    if (inlineAnswers) {
      handleAnswerBox()
    }

    const delay = inlineAnswers ? STEP_DELAY_INLINE + STEP_DELAY : STEP_DELAY
    clearTimeout(nextTimerRef.current)
    nextTimerRef.current = setTimeout(next, delay)
  }

  const scrollToResults = () => {
    const summary = document.getElementById('quiz-summary')

    if (summary) {
      summary.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }

  const score = (
    questions.filter(
      ({ _key }) => (answers[_key] as { key: string; correct: boolean } | undefined)?.correct,
    ) || []
  ).length
  const scoreTitle = getScoreFeedback({
    score,
    total: questions?.length,
    feedback,
  })
  const percentage = (score / questions?.length) * 100

  const currentCorrect = answers[current._key]?.correct

  const questionAnswered = !!answers[current._key]

  return (
    <main {...bem('', { 'no-scroll': preventScroll, fluid })} ref={wrapperRef}>
      <div {...bem('wrapper')}>
        <Transition
          {...bem('answer-box-wrapper')}
          updateKey={showAnswerBox.toString()}
          enter={500}
          exit={500}
        >
          {showAnswerBox && (
            <div {...bem('answer-box', { correct: currentCorrect, wrong: !currentCorrect })}>
              <strong>{currentCorrect ? 'Riktig!' : 'Feil!'}</strong>
              <span>
                {score} av {question} riktige
              </span>
              <Button
                {...bem('button', { correct: currentCorrect, wrong: !currentCorrect })}
                onClick={next}
              >
                Neste ({count} sek)
              </Button>
            </div>
          )}
        </Transition>
        <nav {...bem('controls')}>
          {current.type !== 'score' && question !== 0 && (
            <div {...bem('nav')}>
              {/* TODO: Figure out why the #"$"#% this was made into a link? */}
              {/* {inlineAnswers && (
                <Link
                  href={Array.isArray(backTo) ? backTo[0] : backTo}
                  type="button"
                  {...bem('control', 'back')}
                  onClick={back}
                >
                  <Icon icon="chevron" direction="left" {...bem('icon')} />
                </Link>
              )} */}
              {inlineAnswers && (
                <button {...bem('control', 'back')} type="button" onClick={back}>
                  <Icon icon="chevron" direction="left" {...bem('icon')} />
                </button>
              )}

              <span {...bem('count')}>
                {question} av {questions?.length}
              </span>
              {inlineAnswers && questionAnswered && (
                <button type="button" {...bem('control', 'next')} onClick={next}>
                  <Icon icon="chevron" direction="right" {...bem('icon')} />
                </button>
              )}
            </div>
          )}

          {!fluid && (
            <Link
              href={Array.isArray(backTo) ? backTo[0] : backTo}
              aria-label="Lukk"
              {...bem('control', 'right')}
            >
              <Icon icon="close" {...bem('icon')} />
            </Link>
          )}
        </nav>

        <Transition {...bem('question')} updateKey={current._key} enter={1500} exit={1500}>
          {current.type === 'intro' && (
            <div {...bem('intro')}>
              <h1 {...bem('title', 'intro')}>{current.title}</h1>
              <p {...bem('preamble')}>{current.preamble}</p>
              <button type="button" {...bem('option', 'promoted')} onClick={next}>
                Start
              </button>
            </div>
          )}

          {current.type === 'score' && (
            <div {...bem('score')} style={{ '--score': `${percentage}%` } as CSSProperties}>
              <div
                {...bem('score-card', {
                  good: percentage >= 70,
                  bad: percentage < 70,
                })}
              >
                <div {...bem('score-card-content')}>
                  <h1 {...bem('result')}>
                    Ditt resultat
                    <div>
                      {score} av {questions.length}
                    </div>
                  </h1>

                  {scoreTitle && <p {...bem('preamble')}>{scoreTitle}</p>}
                </div>
              </div>

              <button type="button" {...bem('option', 'results')} onClick={scrollToResults}>
                Se resultater
              </button>

              <div {...bem('summary')} id="quiz-summary">
                {questions.map((item, index) => (
                  <section key={item._key} {...bem('summary-item')}>
                    <h2 {...bem('sub-title')}>
                      <strong {...bem('index')}>Spørsmål {index + 1}</strong>
                      <span>{item.title}</span>
                    </h2>

                    <ul {...bem('summary-list')}>
                      {item.answers.map((answer) => {
                        const selected = answers[item._key]?.key === answer._key
                        const wrong = !answer.correct && selected
                        const correct = answer.correct

                        return (
                          <li
                            key={answer._key}
                            {...bem('summary-answer', {
                              wrong,
                              correct,
                            })}
                          >
                            {answer.title}

                            {wrong && <Icon {...bem('answer-icon')} icon="close" />}
                            {correct && <Icon {...bem('answer-icon')} icon="check" />}
                          </li>
                        )
                      })}
                    </ul>
                  </section>
                ))}
              </div>

              <button type="button" {...bem('option', 'restart')} onClick={restart}>
                Start på nytt
              </button>
            </div>
          )}

          {['intro', 'score'].indexOf(current.type) === -1 && (
            <div {...bem('step')}>
              <h1 {...bem('title')}>{current.title}</h1>

              {current.media && (
                <div {...bem('media')}>
                  {current.media._type === 'image' && (
                    <Image {...current.media} alt={current.media.alt} />
                  )}
                  {current.media._type === 'youtube' && <YouTube {...current.media} />}
                  {current.media._type === 'video' &&
                    current.media.videoType === 'internal' &&
                    current.media.video?.asset && (
                      <VideoPlayer
                        playbackId={current.media.video?.asset?.playbackId}
                        aspectRatio={current.media.video?.asset?.data?.aspectRatio ?? ''}
                        caption={current.media.caption || ''}
                        title={title || ''}
                      />
                    )}
                  {current.media._type === 'video' && current.media.videoType === 'youtube' && (
                    <YouTube id={current.media.id} caption={current.media.caption || ''} />
                  )}
                </div>
              )}

              <nav {...bem('options', current?.answers?.length % 2 === 0 ? 'even' : 'odd')}>
                {(current.answers || []).map(({ _key, title, correct }) => {
                  const selected = answers[current._key]?.key === _key
                  const showCorrect = correct && inlineAnswers && answers[current._key]
                  const showWrong = !correct && inlineAnswers && selected
                  const disabled = answers[current._key]

                  return (
                    <button
                      type="button"
                      key={_key}
                      {...bem('option', {
                        selected,
                        correct: showCorrect,
                        wrong: showWrong,
                      })}
                      disabled={disabled}
                      onClick={selectAnswer(current._key, _key, correct)}
                    >
                      {title}
                      {showCorrect && (
                        <span {...bem('selected-icon')}>
                          <Icon icon="check" />
                        </span>
                      )}
                      {showWrong && (
                        <span {...bem('selected-icon')}>
                          <Icon icon="x" />
                        </span>
                      )}
                    </button>
                  )
                })}
              </nav>
            </div>
          )}
        </Transition>
      </div>
    </main>
  )
}

interface EntryProps {
  title: string
  slug: string
  topicTheme?: {
    background: string
    promoted: string
  }
}

// TODO: Replace with new entry(?)
export function QuizEntry({ title, slug, topicTheme }: EntryProps) {
  return (
    <Link
      {...bem('entry')}
      href={'/quiz/' + slug}
      style={
        {
          '--color-background': topicTheme?.background,
          '--color-promoted': topicTheme?.promoted,
        } as CSSProperties
      }
    >
      {/* Needs fix */}
      <NextImage
        src="/icons/quiz.png"
        alt=""
        {...bem('illustration', 'entry')}
        width={135}
        height={135}
      />

      <span {...bem('tag')}>Quiz</span>
      <h3 {...bem('entry-title')}>{title}</h3>
    </Link>
  )
}
